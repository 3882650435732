var columns = [{
  title: "邀请人",
  dataIndex: "nickname",
  key: "nickname",
  width: "30%",
  scopedSlots: {
    customRender: "nickname"
  }
}, {
  title: "被邀请人数量",
  dataIndex: "invitecount",
  key: "invitecount",
  width: "25%",
  sorter: true,
  scopedSlots: {
    customRender: "invitecount"
  }
}, {
  title: "邀请排名",
  dataIndex: "order",
  key: "order",
  width: "20%",
  scopedSlots: {
    customRender: "order"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  //   fixed: "right",
  width: "25%",
  scopedSlots: {
    customRender: "action"
  }
}];
var friendsColumns = [{
  title: "邀请ID",
  dataIndex: "id",
  key: "id",
  // width: '11%',
  scopedSlots: {
    customRender: "id"
  }
}, {
  title: "邀请口令",
  dataIndex: "inviteCode",
  key: "invitecode",
  // width: '11%',
  scopedSlots: {
    customRender: "invitecode"
  }
}, {
  title: "邀请人",
  dataIndex: "inviteusername",
  key: "inviteusername",
  // width: '11%',
  scopedSlots: {
    customRender: "inviteusername"
  }
}, {
  title: "被邀请人",
  dataIndex: "username",
  key: "username",
  // width: '11%',
  scopedSlots: {
    customRender: "username"
  }
}, {
  title: "被邀请人邮箱",
  dataIndex: "email",
  key: "email",
  // width: '11%',
  scopedSlots: {
    customRender: "email"
  }
}, {
  title: "被邀请人注册时间(GMT+8)",
  dataIndex: "invitetime",
  key: "invitetime",
  // width: '11%',
  scopedSlots: {
    customRender: "invitetime"
  }
}, {
  title: "被邀请人-设备ID",
  dataIndex: "deviceid",
  key: "deviceid",
  // width: '11%',
  scopedSlots: {
    customRender: "deviceid"
  }
}, {
  title: "被邀请人-IP",
  dataIndex: "ipaddress",
  key: "ipaddress",
  // width: '11%',
  scopedSlots: {
    customRender: "ipaddress"
  }
}, {
  title: "被邀请人-设备端",
  dataIndex: "os",
  key: "os",
  // width: '11%',
  scopedSlots: {
    customRender: "os"
  }
}, {
  title: "被邀请人-设备端Model",
  dataIndex: "model",
  key: "model",
  // width: '11%',
  scopedSlots: {
    customRender: "model"
  }
}, {
  title: "被邀请人-系统版本",
  dataIndex: "edition",
  key: "edition",
  // width: '11%',
  scopedSlots: {
    customRender: "edition"
  }
}, {
  title: "被邀请人-当前系统版本",
  dataIndex: "version",
  key: "version",
  // width: '11%',
  scopedSlots: {
    customRender: "version"
  }
}, {
  title: "被邀请人-是否破解设备",
  dataIndex: "prisonbreak",
  key: "prisonbreak",
  scopedSlots: {
    customRender: "prisonbreak"
  }
}, {
  title: "CBK发放有效性",
  dataIndex: "cbkvalidity",
  key: "cbkvalidity",
  scopedSlots: {
    customRender: "cbkvalidity"
  }
}];
export { columns, friendsColumns };