var searchKeys = [{
  key: "username",
  label: "邀请人Username",
  placeholder: "邀请人Username",
  required: false,
  rules: [],
  input: true
}, {
  key: "nickname",
  label: "邀请人昵称",
  placeholder: "邀请人昵称",
  required: false,
  rules: [],
  input: true
}, {
  key: "uid",
  label: "邀请人id",
  placeholder: "邀请人id",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var searchRecordKeys = [{
  key: "inviteid",
  label: "邀请ID",
  placeholder: "邀请ID",
  required: false,
  rules: [],
  input: true
}, {
  key: "invitecode",
  label: "邀请口令",
  placeholder: "邀请口令",
  required: false,
  rules: [],
  input: true
}, {
  key: "inviteeusername",
  label: "被邀请人Username",
  placeholder: "被邀请人Username",
  required: false,
  rules: [],
  input: true
}, {
  key: "inviterusername",
  label: "邀请人Username",
  placeholder: "邀请人Username",
  required: false,
  rules: [],
  input: true
}, {
  key: "inviteeuid",
  label: "被邀请人uid",
  placeholder: "被邀请人uid",
  required: false,
  rules: [],
  input: true
}, {
  key: "inviteruid",
  label: "邀请人uid",
  placeholder: "邀请人uid",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys, searchRecordKeys };